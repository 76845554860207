.fresh-var {
  --v-anchor-base: #03ACF2;
  --v-primarylight-base: #B3E5FC;
  --v-primary-base: #03ACF2;
  --v-primarydark-base: #01579B;
  --v-secondarylight-base: #C8E6C9;
  --v-secondary-base: #4CAF50;
  --v-secondarydark-base: #357A38;
}

.passion-var {
  --v-anchor-base: #E91E63;
  --v-primarylight-base: #F8BBD0;
  --v-primary-base: #E91E63;
  --v-primarydark-base: #880E4F;
  --v-secondarylight-base: #C5CAE9;
  --v-secondary-base: #304FFE;
  --v-secondarydark-base: #1A237E;
}

.smart-var {
  --v-anchor-base: #00BCD4;
  --v-primarylight-base: #B2EBF2;
  --v-primary-base: #00BCD4;
  --v-primarydark-base: #00838F;
  --v-secondarylight-base: #FFECB3;
  --v-secondary-base: #FFA000;
  --v-secondarydark-base: #FF6F00;
}

.burgundy-var {
  --v-anchor-base: #e91e63;
  --v-primarylight-base: #f8bbd0;
  --v-primary-base: #e91e63;
  --v-primarydark-base: #ad1457;
  --v-secondarylight-base: #bbdefb;
  --v-secondary-base: #2196f3;
  --v-secondarydark-base: #1565c0;
}

.ocean-blue-var {
  --v-anchor-base: #2196f3;
  --v-primarylight-base: #bbdefb;
  --v-primary-base: #2196f3;
  --v-primarydark-base: #0d47a1;
  --v-secondarylight-base: #b2ebf2;
  --v-secondary-base: #00bcd4;
  --v-secondarydark-base: #006064;
}

.green-leaf-var {
  --v-anchor-base: #009688;
  --v-primarylight-base: #E0F7FA;
  --v-primary-base: #009688;
  --v-primarydark-base: #00695C;
  --v-secondarylight-base: #CFD8DC;
  --v-secondary-base: #607D8B;
  --v-secondarydark-base: #37474F;
}

.money-var {
  --v-anchor-base: #4CAF50;
  --v-primarylight-base: #C8E6C9;
  --v-primary-base: #4CAF50;
  --v-primarydark-base: #2E7D32;
  --v-secondarylight-base: #FFECB3;
  --v-secondary-base: #FFA000;
  --v-secondarydark-base: #FF6F00;
}

.grayscale-var {
  --v-anchor-base: #546E7A;
  --v-primarylight-base: #CFD8DC;
  --v-primary-base: #546E7A;
  --v-primarydark-base: #263238;
  --v-secondarylight-base: #D7CCC8;
  --v-secondary-base: #6D4C41;
  --v-secondarydark-base: #3E2723;
}

.cloud-var {
  --v-anchor-base: #03A9F4;
  --v-primarylight-base: #B3E5FC;
  --v-primary-base: #03A9F4;
  --v-primarydark-base: #01579B;
  --v-secondarylight-base: #FFE0B2;
  --v-secondary-base: #FF9800;
  --v-secondarydark-base: #E65100;
}

.joker-var {
  --v-anchor-base: #673AB7;
  --v-primarylight-base: #D1C4E9;
  --v-primary-base: #673AB7;
  --v-primarydark-base: #311B92;
  --v-secondarylight-base: #B2EBF2;
  --v-secondary-base: #00BCD4;
  --v-secondarydark-base: #006064;
  --v-primarylight-lighten1: #ede0ff;
}

.violet-var {
  --v-anchor-base: #EC407A;
  --v-primarylight-base: #FCE4EC;
  --v-primary-base: #EC407A;
  --v-primarydark-base: #D81B60;
  --v-secondarylight-base: #D1C4E9;
  --v-secondary-base: #673AB7;
  --v-secondarydark-base: #311B92;
}

.violeta-var {
  --v-anchor-base: #9C27B0;
  --v-primarylight-base: #E1BEE7;
  --v-primary-base: #9C27B0;
  --v-primarydark-base: #6A1B9A;
  --v-secondarylight-base: #DCEDC8;
  --v-secondary-base: #8BC34A;
  --v-secondarydark-base: #558B2F;
}

.roseGold-var {
  --v-anchor-base: #EC407A;
  --v-primarylight-base: #FFF8E1;
  --v-primary-base: #FFA000;
  --v-primarydark-base: #FFA000;
  --v-secondarylight-base: #FCE4EC;
  --v-secondary-base: #EC407A;
  --v-secondarydark-base: #D81B60;
}

.deepBlue-var {
  --v-anchor-base: #3F51B5;
  --v-primarylight-base: #E8EAF6;
  --v-primary-base: #3F51B5;
  --v-primarydark-base: #283593;
  --v-secondarylight-base: #E1F5FE;
  --v-secondary-base: #03A9F4;
  --v-secondarydark-base: #0277BD;
}

.ubuntu-var {
  --v-anchor-base: #EF6C00;
  --v-primarylight-base: #FFF3E0;
  --v-primary-base: #EF6C00;
  --v-primarydark-base: #E65100;
  --v-secondarylight-base: #F3E5F5;
  --v-secondary-base: #9C27B0;
  --v-secondarydark-base: #7B1FA2;
}

.greenNature-var {
  --v-anchor-base: #009688;
  --v-primarylight-base: #E0F7FA;
  --v-primary-base: #009688;
  --v-primarydark-base: #00695C;
  --v-secondarylight-base: #F1F8E9;
  --v-secondary-base: #689F38;
  --v-secondarydark-base: #33691E;
}

.vampire-var {
  --v-anchor-base: #f44336;
  --v-primarylight-base: #FFEBEE;
  --v-primary-base: #f44336;
  --v-primarydark-base: #E53935;
  --v-secondarylight-base: #ECEFF1;
  --v-secondary-base: #607D8B;
  --v-secondarydark-base: #455A64;
}

.mint-var {
  --v-anchor-base: #2196f3;
  --v-primarylight-base: #bbdefb;
  --v-primary-base: #2196f3;
  --v-primarydark-base: #0d47a1;
  --v-secondarylight-base: #b3e5fc;
  --v-secondary-base: #03a9f4;
  --v-secondarydark-base: #01579b;
}

.gold-var {
  --v-anchor-base: #FF9100;
  --v-primarylight-base: #FFF9C4;
  --v-primary-base: #FF9100;
  --v-primarydark-base: #FF6D00;
  --v-secondarylight-base: #EFEBE9;
  --v-secondary-base: #8D6E63;
  --v-secondarydark-base: #4E342E;
}

.botani-var {
  --v-anchor-base: #689F38;
  --v-primarylight-base: #DCEDC8;
  --v-primary-base: #689F38;
  --v-primarydark-base: #33691E;
  --v-secondarylight-base: #FCE4EC;
  --v-secondary-base: #F06292;
  --v-secondarydark-base: #AD1457;
}

.littleQueen-var {
  --v-anchor-base: #00BCD4;
  --v-primarylight-base: #E0F7FA;
  --v-primary-base: #00BCD4;
  --v-primarydark-base: #006064;
  --v-secondarylight-base: #FCE4EC;
  --v-secondary-base: #F06292;
  --v-secondarydark-base: #AD1457;
}

.purple-var {
  --v-anchor-base: #9c27b0;
  --v-primarylight-base: #f3e5f5;
  --v-primary-base: #9c27b0;
  --v-primarydark-base: #6a1b9a;
  --v-secondarylight-base: #ea80fc;
  --v-secondary-base: #aa00ff;
  --v-secondarydark-base: #4a148c;
}

.black-var {
  --v-anchor-base: #212121;
  --v-primarylight-base: #9e9e9e;
  --v-primary-base: #212121;
  --v-primarydark-base: #000;
  --v-secondarylight-base: #E0E0E0;
  --v-secondary-base: #757575;
  --v-secondarydark-base: #424242;
}

.brown-var {
  --v-anchor-base: #795548;
  --v-primarylight-base: #d7ccc8;
  --v-primary-base: #795548;
  --v-primarydark-base: #4e342e;
  --v-secondarylight-base: #ccb0b0;
  --v-secondary-base: #794848;
  --v-secondarydark-base: #3e2723;
}
