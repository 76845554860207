.page-fadeUp-transition-enter {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.page-fadeUp-transition-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
  -webkit-transition: opacity 400ms, transform 400ms;
  transition: opacity 400ms, transform 400ms;
}

.page-fadeUp-transition-exit {
  opacity: 1;
}

.fragment-fadeUp {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

[class$="transition-exit"] .fragment-fadeUp{
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
  -webkit-transition: opacity 400ms, transform 400ms;
  transition: opacity 400ms, transform 400ms;
}