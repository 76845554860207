.page-nav {
  .btn-floating {
    transform: scale(0.5);
    transition: all 0.5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 86px;
    font-weight: $font-bold;
    background: $palette-primary-light !important;
    .icon {
      color: $palette-primary-dark;
      font-size: 40px;
    }
    &:hover {
      filter: brightness(0.95);
    }
  }
  z-index: 200;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 56px;
  nav {
    li {
      float: none;
      opacity: 0;
      transition: all 0.4s ease;
      position: relative;
    }
  }
  &.show {
    .btn-floating {
      opacity: 1;
      transform: scale(1);
    }
  }
  .section-nav {
    .scrollnav {
      bottom: 140px;
      margin: 0 0 76px 22px;
      padding: 0;
      position: relative
    }
    a {
      margin-bottom: $spacing3;
      position: relative;
      transition: all 0.4s ease;
      width: 12px;
      height: 12px;
      @include shadow2;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid $palette-primary-dark;
      @include use-theme(border-color, $light-text-hint, $dark-text-hint);
      display: block;
      transition: all 0.4s ease;
      color: transparent;
      padding: 0
    }
    li.current {
      a {
        background: $palette-primary-dark;
        border: 1px solid $palette-primary-light;
      }
    }
  }
  &:hover {
    .section-nav li {
      opacity: 1;
      top: 0 !important;
    }
  }
}

.material-tooltip {
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 4px;
  background: $light-text-secondary
}